/*** NGPRIME CUSTOM ***/
.p-datatable{
  box-shadow: 0px 1px 8px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10) !important;
}

.p-datatable-thead>tr>th{
  border: unset !important;
  border-width: unset;
}

span.pi.pi-filter-slash{
  color: var(--error-color);
}

.p-paginator{
  justify-content: space-between !important;
}

.p-datatable>.p-datatable-wrapper{
  overflow: unset !important;
}

.thHeader{
  background-color: #F9FAFB !important;
}

.trColumnNames{
  border: unset !important;
}
.trColumnNames>th{
  border: unset !important;
  padding: 1rem 1rem 0rem 1rem !important;
  background-color: #F9FAFB !important;
}

.trColumnNames>form{
  height: 100px !important;
}
