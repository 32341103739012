.icon-color-primary{
  color: var(--primary-color);
}
.icon-color-success{
  color: var(--success-color);
}
.icon-color-warning{
  color: var(--warning-color);
}
.icon-color-error{
  color: var(--error-color);
}
