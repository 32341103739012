/** Bootrstrap-based **/

/*** SPACING ***/
.my-1{
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-2{
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-3{
  margin-top: 32px;
  margin-bottom: 32px;
}
.p-1{
  padding: 8px;
}
.p-2{
  padding: 16px;
}

.mt-1{
  margin-top: 8px !important;
}
.mt-2{
  margin-top: 16px !important;
}
.mt-3{
  margin-top: 32px !important;
}
.mt-4{
  margin-top: 48px !important;
}

.mb-1{
  margin-bottom: 8px !important;
}
.mb-2{
  margin-bottom: 16px !important;
}
.mb-3{
  margin-bottom: 32px !important;
}
.mb-4{
  margin-bottom: 48px !important;
}

.ml-1{
  margin-left: 8px;
}
.ml-2{
  margin-left: 16px;
}
.ml-2{
  margin-left: 32px;
}
.mr-1{
  margin-right: 8px;
}
.mr-2{
  margin-right: 16px;
}
.mr-3{
  margin-right: 32px;
}

.w-100{
  width: 100% !important;
}
.w-50{
  width: 50% !important;
}
.w-25{
  width: 25% !important;
}
