@use '@angular/material' as mat;
@include mat.core();
@import '@angular/material/theming';

// Custom styles imports
@import url('./styles//styles-ngprime.scss');
@import url('./styles/styles-spacing.scss');
@import url('./styles/styles-icons.scss');
@import url('./styles/styles-dialogs.scss');


:root {
  --primary-color: #19458D;
  --primary-color-013: rgb(100, 136, 106, 0.129);
  --secundary-color: #00a8e0;
  --success-color: #00C851;
  --error-color: #F04438;
  --error-color-013: rgba(238, 46, 49, 0.129);
  --warning-color: #f7c30d;
  --warning-color-013: #ff991421;

  /* Custom colors */
  --color-negro-1: #101828;
  --color-gris-1: #667085;
  --color-azul-cpe: #2b3e4c;

}

html, body {
  height: 100%;
  width: 100%;
  /* Safari/Chrome, other WebKit */
  -webkit-box-sizing: border-box;
  /* Firefox, other Gecko */
  -moz-box-sizing: border-box;
  /* Opera/IE 8+ */
  box-sizing: border-box;
  margin: 0;
}

/*** FONT ***/
@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter/Inter-Regular.ttf);
  font-weight: normal;
}
@font-face {
  font-family: Inter;
  src: url(assets/fonts/Inter/Inter-Bold.ttf);
  font-weight: bold;
}
$custom-typography: mat.define-typography-config(
  $font-family: Inter,
  $display-4: mat.define-typography-level($font-size: 2.88rem,$font-weight:300),
  $display-3: mat.define-typography-level($font-size: 2.378rem,$font-weight:400),
  $display-2: mat.define-typography-level($font-size: 1.92rem,$font-weight:400),
  $display-1: mat.define-typography-level($font-size: 1.68rem,$font-weight:400),
  $headline: mat.define-typography-level($font-size: 30px,$font-weight:400),
  $title: mat.define-typography-level($font-size: 30px, $font-weight:600),
  $subheading-2: mat.define-typography-level($font-size: 1.08rem,$font-weight:600),
  $subheading-1: mat.define-typography-level($font-size: 1.08rem,$font-weight:400),
  $body-2: mat.define-typography-level($font-size: 16px,$font-weight:600),
  $body-1: mat.define-typography-level($font-size: 16px,$font-weight:400),
  $caption: mat.define-typography-level($font-size: 0.6rem,$font-weight:400),
  $button: mat.define-typography-level($font-size: 16px,$font-weight:600),
  $input: mat.define-typography-level($font-size: 16px, $line-height: 1.125, $font-weight: 400),
);
@include mat.all-component-typographies($custom-typography);
html, body {
  font-family: 'Inter', sans-serif;
  color: var(--color-azul-cpe);
}

/*** FLEX ***/
.flex{
  display: flex;
}
.row{
  flex-direction: row;
}
.column{
  flex-direction: column;
}
.flexAllCenter{
  justify-content: center;
  align-items: center;
}

.justifyContentCenter{
  justify-content: center;
}
.justifyContentSpaceBetween{
  justify-content: space-between;
}
.alignItemsCenter{
  align-items: center;
}

/****** CUSTOM STYLES *******/
.title1{
  font-weight: 600;
  color: var(--color-azul-cpe);
  font-size: 30px;
  line-height: 38px;
}

.subtitle{
  color: var(--color-gris-1);
  font-size: 16px;
  text-align: center;
}

/*** FORMS ****/
mat-form-field{
  font-size: 16px !important;
}
.mat-form-field-infix{
  padding: 0.5em 0 1em 0 !important;
}


input.mat-input-element{
  height: 100%;
}

.fieldReadonly{
  .mat-form-field-outline{
    background-color: #f3f3f3;
    border-radius: 5px;
  }
}

.formField{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 8px;

  label {
    margin-bottom: 6px;
  }
}

/* <mat-form-field class="formFieldDate" */
.formFieldDate{
  .mat-form-field-infix{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0px 4px 4px 4px !important;
    input{
      color: rgba(0,0,0,.87) !important;
    }
  }
}

/* INPUT FILE */
input[type="file"]{
  display: none;
}
.customInputFile {
  background-color: var(--primary-color);
  color: white;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
}
.customInputFile:hover {
  background-color: #0e3066;
}

.buttonsFormContainer{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inputIcon{
  margin-right: 8px;
  color: var(--color-gris-1);
}


.linkFormatted{
  text-decoration: unset;
  color: var(--primary-color) !important;
}


/** BUTTONS **/
.negativeBtnRaised{
  background-color: var(--error-color);
  color: #FFFFFF;
}

.roundBtn{
  padding: 4px;
  border-radius: 50% !important;
}

/** Tabs **/
.mat-tab-label-active{
  color: var(--primary-color);
}


/**  TEXT **/
.link{
  color: var(--primary-color);
  font-weight: 600;
  text-decoration-line: unset;
}
.linkUnderline{
  color: var(--primary-color);
  text-decoration-line: underline;
  font-weight: 600;
}
.link:hover, .linkUnderline:hover{
  cursor: pointer;
}

.tableTitle{
  font-weight: 600;
  color: var(--color-azul-cpe);
}


/** Quitar estilos por defecto **/

/* Para ocultar las flechas en todos los inputs number */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/** para que no quede tan gris el desabilitado **/
.mat-select-disabled .mat-select-value {
  color: rgba(0, 0, 0, .80);
}

.mat-input-element:disabled, .mat-form-field-type-mat-native-select.mat-form-field-disabled .mat-form-field-infix::after {
  color: rgba(0, 0, 0, .80);
}

.p-datatable .p-datatable-thead>tr>th, .dialogTitle, .loadingContainer{color: var(--color-azul-cpe) !important;}
.loadingContainer, h1,h2,h3,h4,h5{
  color: var(--color-azul-cpe) !important;
}

.formulario {
  margin-top: 50px;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-column-gap: 10px;
  grid-row-gap: 10px;

  &__item {
      grid-column: var(--columna) / span var(--cantidad) !important;
      grid-row: var(--linea) !important;
  }
}

.formFieldLogin {
  min-width: 99%;
  width: 99%;
  font-size: 16px;
}

.botonCenter {
  margin: 0;
  position: absolute;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.buttons {
  display: flex;
  width: 432px;
  height: 44px;
  align-items: flex-start;
  gap: 12px;
}

.buttons button {
  width: 210px;
  height: 100%;
  border-radius: 8px;
}

@media screen and (max-width: 945px) {
  .grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(auto-fill, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      grid-template-areas:
      "a b";

      &__item {
          grid-column: 1 / 1 !important;
          grid-row: auto !important;
      }
  }
}

@media screen and (min-width: 946px) and (max-width: 1135px) {
  .grid {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(auto-fill, 1fr);
      grid-column-gap: 10px;
      grid-row-gap: 10px;
      grid-template-areas:
      "a";

      &__item {
          grid-column: auto / auto !important;
          grid-row: auto !important;
      }
  }
}

.linkPortal{
  color: #2b3e4c !important;
  font-weight: 600;
  text-decoration: none;
}
