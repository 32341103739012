.mat-dialog-container{
  border-radius: 12px !important;
}

.mat-dialog-title {
  margin-bottom: 32px !important;

  .dialogTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
    font-style: normal;
    margin-bottom: 8px;
  }
  .dialogSubtitle {
    color: var(--gray-500, #667085);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}

.mat-dialog-content{
  width: 100%;
  margin: unset !important;
  padding: unset !important;
}

.formDialog>.field{
  display: flex;
  flex-direction: column;

  label {
    margin-bottom: 4px;
  }
}

.mat-dialog-actions{
  display: flex;
  justify-content: space-between;
  margin-top: 32px !important;
  flex-wrap: nowrap !important;

  .actionButton{
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;
    padding-top: 4px;
    padding-bottom: 4px;
    box-shadow: 0px 3px 6px 0px rgba(16, 24, 40, 0.05);
  }
  .submit{
    color: white;
  }
}
